.visible_container {
  margin-top: 80px;
}

.visible_div {
  margin-block: 15px;
}
.bulletpoints {
  margin-block: 10px;
}
