:root {
  --background-color: #ffffff;
  --detail-color: black;

  --font-color: var(--detail-color);
  --detail-font-color: var(--background-color);
}

.nav-link {
  justify-content: center;
  padding: 10px;
  font-size: 20px;
  color: var(--detail-font-color);
  text-decoration: none;
  cursor: pointer;
}

.nav-link:hover {
  font-weight: bold;
  color: var(--detail-font-color);
}

.nav-button {
  padding: 10px;
  font-size: 25px;
  color: var(--detail-font-color);
  background: var(--detail-color);
  border-style: none;
  text-decoration: none;
}

.nav-bar {
  top: 0;
  display: flex;
  justify-content: center;
  width: calc(100vw);
  height: 50px;
  padding: 5px;
  position: fixed;
  z-index: 9999;
  background-color: var(--detail-color);
  transition: background 1s;
}

.nav-bar-hidden {
  display: flex;
  justify-content: center;
  width: calc(100vw);
  height: 50px;
  padding: 5px;
  position: fixed;
  background-color: transparent;
}
